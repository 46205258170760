import React, {useState}from 'react'
import bgPage from './bgPage.png'
import exclusiveReview from './exclusiveReview.png'
import ComingSoonText from './ComingSoonText.png'
import dropyouremail from './dropyouremail.png'
import emailbox from './emailbox.png'
import agreeTerms from './agreeTerms.png'
import launching from './launching.png'
import StayConnected from './StayConnected.png'
import fb from './fb.png'
import insta from './insta.png'
import twitter from './twitter.png'
import youtube from './youtube.png'
import linkedin from './linkedin.png'
import tablerExternal from './tablerExternal.png'

function ComingSoon() {

    const [email, setEmail]= useState('')


    const handleSubmit = async (e) => {
        e.preventDefault()

        const body = {email}

        const response = await fetch('/api/emails/', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const json = await response.json()

        if(response.ok){
            setEmail("")
            alert("New Email Subscribed")
        }
    }


  return (
    <div className='bg-page'><img src={bgPage}/>
        <div className='exclusiveReview'>
            <img src={exclusiveReview}/>
        </div>
        <div className='BG-page'>
            <div className='ComingSoonText'>
                <img src={ComingSoonText}/>
                <div className='dropyouremail'>
                <img src={dropyouremail}/>
                <div className='emailbox'>
                <img src={emailbox}/>
                <label>Email</label>
                <input className='input-email'
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <button onClick={handleSubmit}>Subscribe Now</button>
                <div className='checkbox'>
                <input 
                type="checkbox" 
                required name="terms"
                />
                <img className='terms' src={agreeTerms}/>
                <div className='StayConnected'>
                <img src={StayConnected}/>
                <div className='footer'>
                <img className='twitter' src={twitter}/>
                <img className='youtube' src={youtube}/>
                <img className='fb' src={fb}/>
                <img className='linkedin' src={linkedin}/>
                <img className='insta' src={insta}/>
                
            </div>
            
            </div>
            
            </div>
            </div>
            </div>
            </div>
            
            
           
        </div>
        <div className='main_footer'>
            <div className='launching'>
                <img src={launching}/>
            </div>
           
            
            <div className='web-link'>
                <img src={tablerExternal}/>
                <p> <a href='https://www.sellebration.ltd/' target="_blank">Visit - sellebration.Ltd</a> </p>
            </div>
            <div className='termsandpolicy'>
                <a href='https://www.sellebration.ltd/privacypolicy' target="_blank">Privacy Policy</a>
                <a href='https://www.sellebration.ltd/contactus' target="_blank">Terms and Conditions</a>
            </div>
            </div>
    </div>
    


  )
}

export default ComingSoon