import ComingSoon from "./ComingSoon";


function App() {
  return (
   <ComingSoon/>
  );
}

export default App;
